import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout/Layout';
import Container from '../components/layout/Container';
import SEO from '../components/misc/seo';

const Image = ({ img }) => (
  <div className="w-full p-8">
    <div className="rounded-lg overflow-hidden">
      <img src={require(`../images/${img}`)} className="object-cover" />
    </div>
  </div>
);

Image.propTypes = {
  img: PropTypes.string,
};

function UnsterstützungUndFörderung() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <section className="bg-white text-green-800">
        <div className="py-24">
          <Container>
            <div className="text-center mb-12">
              <h1 className="font-serif text-4xl sm:text-6xl">
                Unterstützung und Förderung
              </h1>
            </div>
            <div className="md:max-w-4xl mx-auto mb-12">
              <p>
                Mein Unterstützungs- und Förderungskonzept widmet sich dem
                Aufbau sozialer Beziehungen, der Förderung von Bewegung,
                Wahrnehmung, Handlungsplanung, lebenspraktischen Tätigkeiten und
                der Sprache, der Aufmerksamkeit und Motivation sowie der
                Eigeninitiative und Selbstständigkeit und der Kognition.
              </p>
              <p>
                Die Förderung der Kinder findet mobil in den Kindertagesstätten
                und Zuhause statt. Die mobile Form hat den Vorteil, dass ein
                sehr intensiver Austausch mit den Eltern, Pädagogen möglich wird
                und eine sichere, vertrauensvolle Beziehung zu den Kindern und
                deren Familien schneller aufgebaut wird. Sie kann entweder als
                Einzelkindförderung oder in Kleingruppen stattfinden.
              </p>
            </div>
            <div className="text-center mb-12">
              <h2 className="font-serif text-4xl sm:text-6xl">Methoden</h2>
            </div>
            <div className="md:max-w-6xl mx-auto">
              <div className="flex w-full py-8">
                <div className="w-2/5">
                  <Image img={'frühförderung.jpg'} />
                </div>
                <div className="w-3/5">
                  <h3 className="text-center mb-12 font-serif text-2xl sm:text-4xl">
                    Frühförderung
                  </h3>
                  <p>
                    Die heilpädagogische Entwicklungsförderung ist ein Angebot
                    für Kinder, die in ihrer Gesamtentwicklung oder in
                    Teilbereichen Auffälligkeiten zeigen. Ziel ist es, das Kind
                    ausgehend von seinen individuellen Fähigkeiten in der
                    Entwicklung seiner Gesamtpersönlichkeit zu unterstützen,
                    neue Entwicklungsschritte anzubahnen und dessen
                    Selbstbewusstsein zu stärken. Spielerisch erhalten die
                    Kinder im Rahmen der Frühförderung Entwicklungsanregungen
                    für die Bereiche Wahrnehmung, Bewegung, Spiel, Sprache,
                    Verhalten, Persönlichkeit und den Bereich sozial-emotional.
                    Die mobile heilpädagogische Frühförderung findet entweder im
                    Kindergarten oder zu Hause statt.
                  </p>
                </div>
              </div>
              <div className="flex w-full py-8">
                <div className="w-3/5">
                  <h3 className="text-center my-12 font-serif text-2xl sm:text-4xl">
                    Heilpädagogische Spieltherapie und Entwicklungsförderung
                  </h3>
                  <p>
                    Das Kind entwickelt sich, weil es spielt und es spielt, weil
                    es sich entwickelt! Im Spiel ist es möglich, die
                    sensomotorische, die kognitive, die soziale und die
                    emotionale Entwicklung anzusprechen und zu fördern. Und nur
                    im Spiel, im wirklich freien, non-direktiven Spiel passiert
                    dies durch das Kind selbst. Das ist die wichtigste
                    Voraussetzung für die stärkende Wirkung.
                  </p>
                </div>
                <div className="w-2/5">
                  <Image img={'spieltherapie.jpg'} />
                </div>
              </div>
              <div className="flex w-full py-8">
                <div className="w-2/5">
                  <Image img={'rhythmik.jpg'} />
                </div>
                <div className="w-3/5">
                  <h3 className="text-center my-12 font-serif text-2xl sm:text-4xl">
                    Heilpädagogische Rhythmik
                  </h3>
                  <p>
                    Rhythmik spricht in besonderem Maße die Eigenwahrnehmung an,
                    hilft Sinneseindrücke zu ordnen und zu innerer Struktur zu
                    gelangen. Es werden Konzentration und Ausdauer gefördert und
                    auch gefordert.
                  </p>
                </div>
              </div>
              <div className="flex w-full py-8">
                <div className="w-3/5">
                  <h3 className="text-center my-12 font-serif text-2xl sm:text-4xl">
                    Kreativität &amp; Musik
                  </h3>
                  <p>
                    Methoden aus dem kreativen oder musischen Bereich
                    ermöglichen dem Kind, sich auf seine ganz eigene Art und
                    Weise auszudrücken. Dies kann in einem Rollenspiel sein,
                    beim Werken mit Materialien oder beim Musizieren mit
                    Instrumenten. Fantasie und schöpferische Kräfte finden
                    hierbei ihren Ausdruck.
                  </p>
                </div>
                <div className="w-2/5">
                  <Image img={'musik.jpg'} />
                </div>
              </div>
              <div className="flex w-full py-8">
                <div className="w-2/5">
                  <Image img={'sprache.jpg'} />
                </div>
                <div className="w-3/5">
                  <h3 className="text-center my-12 font-serif text-2xl sm:text-4xl">
                    Sprachheilpädagogische Elemente
                  </h3>
                  <p>
                    Sprache ist mehr als nur Worte! Diese Arbeitsweisen
                    ermöglichen dem Kind einen wichtigen Ausdrucks- und
                    Kommunikationskanal zu erkennen und auch eine Form des
                    Zugangs zu sozialen Kontakten. Märchen, Bilderbücher,
                    Fingerspiele, Klatschverse, Reime und eigens geschaffene
                    Sprechanlässe im Rollenspiel vermitteln spielerisch Spaß an
                    Sprache und regen das Kind an, mit anderen Menschen zu
                    kommunizieren.
                  </p>
                </div>
              </div>
              <div className="flex w-full py-8">
                <div className="w-3/5">
                  <h3 className="text-center my-12 font-serif text-2xl sm:text-4xl">
                    Psychomotorik drinnen und draußen
                  </h3>
                  <p>
                    Psychomotorik umfasst das ganzheitliche Angebot zur
                    Entwicklungsförderung der Beziehung zwischen dem
                    geistig-seelischen und dem körperlich-motorischen Bereich.
                    Wahrnehmung und Motorik bilden die Grundlage für die
                    Entwicklung des Kindes. Die beiden Bereiche sind eng
                    miteinander verknüpft und zeigen eine wechselseitige
                    Verbindung zum psychischen Erleben und zum kognitiven
                    Lernen. Diese Methode sieht das Kind im Mittelpunkt des
                    Denkens und Handelns. Ziel dieses Angebotes ist, die
                    Handlungsfähigkeit des Kindes über die Ich-, Sach- und
                    Sozialkompetenz zu verbessern.
                  </p>
                </div>
                <div className="w-2/5">
                  <Image img={'motorik.jpg'} />
                </div>
              </div>
              <div className="flex w-full py-8">
                <div className="w-2/5">
                  <Image img={'malen.jpg'} />
                </div>
                <div className="w-3/5">
                  <h3 className="text-center my-12 font-serif text-2xl sm:text-4xl">
                    Heilpädagogisches Malen
                  </h3>
                  <p>
                    Diese Methode ermöglicht dem Kind eigene Kreativität und
                    Ausdruckskraft zu entdecken und bei sich zu bleiben. Im
                    Mittelpunkt steht das Experimentieren mit Farben. Das
                    malende Kind findet Freude und Ruhe in sich. Er/sie arbeitet
                    in eigenem Tempo und Stil ohne dass es bewertet wird. Die
                    Wertung liegt in der Wertschätzung des malenden Kindes. Es
                    fühlt sich wohl, stärkt sein Selbstbewusstsein und seinen
                    Selbstwert, in dem es sich selbst Achtsamkeit und
                    Wertschätzung schenkt.
                  </p>
                </div>
              </div>
              <div className="flex w-full py-8">
                <div className="w-3/5">
                  <h3 className="text-center my-12 font-serif text-2xl sm:text-4xl">
                    Arbeit am Ton
                  </h3>
                  <p>
                    Die Arbeit am Ton umfasst die Basissinne: Den Tastsinn die
                    Tiefensensibilität und das Gleichgewicht. Die Kinder
                    berühren den Ton mit den Händen und gleichzeitig werden sie
                    selbst davon berührt (sensumotorischer Prozess). Die Hände
                    folgen dem Bedürfnis der Bewegung, bis dieses sich erfüllt
                    in neuem Gleichgewicht von Wirkung und Rückwirkung. Der Ton
                    ist gestaltbar und vermittelt die eigene Berührung zurück,
                    sodass Veränderung in der Entwicklung möglich werden – wir
                    äußern uns in die Welt hinein.
                  </p>
                </div>
                <div className="w-2/5">
                  <Image img={'ton.jpg'} />
                </div>
              </div>
              <div className="flex w-full py-8">
                <div className="w-2/5">
                  <Image img={'werken.jpg'} />
                </div>
                <div className="w-3/5">
                  <h3 className="text-center my-12 font-serif text-2xl sm:text-4xl">
                    Heilpädagogisches Werken
                  </h3>
                  <p>
                    Im Umgang mit verschiedenen (Werk-) Materialien (wie z.B.
                    Holz, Papier, Stoff, Wolle, Ton, Kleister etc.) und
                    Werkzeugen werden die Kinder ganzheitlich in ihrem Tun und
                    ihren Sinnen angesprochen.
                  </p>
                  <p>
                    Neben feinmotorischen Fähigkeiten werden auch Konzentration,
                    Handlungsplanung, Ausdauer und Anstrengungsbereitschaft
                    gefördert. Die Kinder bauen gerne Schiffchen, Wasserräder,
                    Schwerter, formen Zwergenlandschaften, Krafttiere,
                    Kugelbahnen, weben ein eigenes Täschchen... Das Herstellen
                    selbstgemachter Werke und das Verfolgen eigener Ziele stärkt
                    das Selbstbewusstsein und lässt die Kinder Selbstwirksamkeit
                    spüren, kreative Potentiale und Entwicklungskräfte können
                    sich entfalten.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
}

export default UnsterstützungUndFörderung;
